import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
// import Root from "./Root";

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const VerifyLoginOtp = React.lazy(() => import("../pages/auth/VerifyLoginOtp"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const Password = React.lazy(() => import("../pages/auth/Password"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));
const NewMerchantResetPassword = React.lazy(() => import("../pages/auth/NewMerchantResetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const Documentation = React.lazy(() => import("../pages/documentation/PluginDocumentation"));
const CheckoutPlugin = React.lazy(() => import("../pages/CheckoutPlugin"));
const Profile = React.lazy(() => import("../pages/compliance/Profile"));
const APIInfo = React.lazy(() => import("../pages/apiInfo"));

//call backs
const CallBack = React.lazy(() => import("../pages/callbacks/CallBack"));

// dashboard
const EcommerceDashboard = React.lazy(() => import("../pages/dashboard/Ecommerce/"));

//transactions, payouts
const Transactions = React.lazy(() => import("../pages/apps/Transactions/"));
const Payouts = React.lazy(() => import("../pages/apps/Payouts/"));
const Transfers = React.lazy(() => import("../pages/apps/Transfers/"));
const Disburse = React.lazy(() => import("../pages/apps/Disburse/"));
const Withdrawal = React.lazy(() => import("../pages/apps/Withdrawal/"));
const Settlements = React.lazy(() => import("../pages/apps/Settlements/"));

//settings
const Settings = React.lazy(() => import("../pages/settings/Settings"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const TermsAndConditions = React.lazy(() => import("../pages/TermsAndConditions"));
const GetHelp = React.lazy(() => import("../pages/GetHelp"));

// uikit
const UIElements = React.lazy(() => import("../pages/uikit"));

// widgets
const Widgets = React.lazy(() => import("../pages/widgets/"));

// icons
const Unicons = React.lazy(() => import("../pages/icons/Unicons"));
const FeatherIcons = React.lazy(() => import("../pages/icons/Feather/"));
const BootstrapIcon = React.lazy(() => import("../pages/icons/Bootstrap/"));

// charts
const Charts = React.lazy(() => import("../pages/charts/"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard/ecommerce" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/ecommerce",
      name: "Ecommerce",
      element: <EcommerceDashboard />,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  element: <CalendarApp />,
  header: "Apps",
};

const projectAppRoutes: RoutesProps = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/transactions",
      name: "Transactions",
      element: <Transactions />,
      route: PrivateRoute,
    },
    {
      path: "/payouts",
      name: "Payouts",
      element: <Payouts />,
      route: PrivateRoute,
    },
    {
      path: "/transfers",
      name: "Transfers",
      element: <Transfers />,
      route: PrivateRoute,
    },
    {
      path: "/transfers/disburse",
      name: "Disburse",
      element: <Disburse />,
      route: PrivateRoute,
    },
    {
      path: "/payouts/withdrawal",
      name: "Withdrawal",
      element: <Withdrawal />,
      route: PrivateRoute,
    },
  ],
};

const appRoutes = [
  calendarAppRoutes,
  projectAppRoutes,
];

// pages
const extrapagesRoutes: RoutesProps = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
   
    {
      path: "/settings",
      name: "Settings",
      element: <Settings />,
      route: PrivateRoute,
    },
    {
      path: "/api",
      name: "APIInfo",
      element: <APIInfo />,
      route: PrivateRoute,
    },
    {
      path: "/profile",
      name: "Profile",
      element: <Profile />,
      route: PrivateRoute,
    },
    {
      path: "/transactions",
      name: "Transactions",
      element: <Transactions />,
      route: PrivateRoute,
    },
    {
      path: "/payouts",
      name: "Payouts",
      element: <Payouts />,
      route: PrivateRoute,
    },
    {
      path: "/settlements",
      name: "Settlements",
      element: <Settlements />,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/components",
  name: "Components",
  icon: "package",
  header: "UI Elements",
  children: [
    {
      path: "/components/ui-elements",
      name: "UIElements",
      element: <UIElements />,
      route: PrivateRoute,
    },
    {
      path: "/components/widgets",
      name: "Widgets",
      element: <Widgets />,
      route: PrivateRoute,
    },
    {
      path: "/icons",
      name: "Icons",
      children: [
        {
          path: "/icons/unicons",
          name: "Unicons",
          element: <Unicons />,
          route: PrivateRoute,
        },
        {
          path: "/icons/feather",
          name: "Feather",
          element: <FeatherIcons />,
          route: PrivateRoute,
        },
        {
          path: "/icons/bootstrap",
          name: "Bootstrap Icon",
          element: <BootstrapIcon />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/components/charts",
      name: "Charts",
      element: <Charts />,
      route: PrivateRoute,
    },
    {
      path: "/tables",
      name: "Tables",
      children: [
        {
          path: "/tables/basic",
          name: "Basic",
          element: <BasicTables />,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/login/otp/:email",
    name: "VerifyLoginOtp",
    element: <VerifyLoginOtp />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/password",
    name: "Password",
    element: <Password />,
    route: PrivateRoute,
  },
  {
    path: "/auth/confirm/:email",
    name: "Confirm",
    element: <Confirm />,
    route: PrivateRoute,
  }, 
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/reset-password",
    name: "ResetPassword",
    element: <ResetPassword />,
    route: PrivateRoute,
  },
  {
    path: "/auth/reset-password/new",
    name: "NewMerchantResetPassword",
    element: <NewMerchantResetPassword />,
    route: PrivateRoute,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    element: <TermsAndConditions />,
    route: Route,
  },
  {
    path: "/get-help",
    name: "Get Help",
    element: <GetHelp />,
    route: Route,
  },
  {
    path: "/callback/confirmation",
    name: "CallBack",
    element: <CallBack />,
    route: Route,
  },
  {
    path: "/docs",
    name: "Docs",
    element: <Documentation />,
    route: Route,
  },
  {
    path: "/checkout",
    name: "Checkout Plugin",
    element: <CheckoutPlugin />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
