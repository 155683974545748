import jwtDecode from "jwt-decode";
import axios from "axios";
import config from "../../config";
import { endpoints } from "./api";

interface JwtPayload {
  exp: number;
  email?: string;
  token: string;
}

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.BASE_URL;

const AUTH_SESSION_KEY = "auth_token";

/**
 * Sets the default authorization
 * @param {*} token
 */

class APICore {
  constructor() {
    axios.defaults.baseURL = config.BASE_URL;
    this.setupInterceptors();
  }

  setupInterceptors = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          (error.response.statusCode === 401 ||
            error.response.data.message === "Invalid or Expired Token" || 
            error.response.data.message === "Unauthorized" ||
           error.response.data.error === "Unauthorized"
          )
        ) {
          this.handleSessionExpiration();
        }
        // Always return a rejected promise so the error can be handled downstream
        return Promise.reject(error);
      }
    );
  };

  handleSessionExpiration = () => {
    localStorage.removeItem(AUTH_SESSION_KEY);
    document.dispatchEvent(new CustomEvent("session-expired"));
  };

  setAuthorization = (token: string | null) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  getEmailFromToken = () => {
    const token = this.getLoggedInUser();
    if (!token) return null;
    try {
      const decoded: any = jwtDecode(token);
      return decoded.email;
    } catch (error) {
      console.warn("Error decoding the token:", error);
      return null;
    }
  };

  /* Fetches data from given url*/
  get = async (url: string, params: any) => {
    try {
      return await axios.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /* post given data to url*/
  create = async (url: string, data: any) => {
    try {
      return await axios.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  /* Updates patch data*/
  updatePatch = async (url: string, data: any) => {
    try {
      return await axios.patch(url, data);
    } catch (error) {
      throw error;
    }
  };

  /* Updates data*/
  update = async (url: string, data: any) => {
    try {
      return await axios.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  /* Deletes data*/
  delete = async (url: string) => {
    try {
      return await axios.delete(url);
    } catch (error) {
      throw error;
    }
  };

  // verify email
  verifyEmail = async (email: string, activation_pin: string) => {
    const url = `${endpoints.verifyEmail}/${email}`;
    const data = { activation_pin };
    try {
      return await axios.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  // resend verification
  resendVerification = async (email: string) => {
    const url = `${endpoints.resendVerification}/${email}`;
    try {
      return await axios.post(url);
    } catch (error) {
      throw error;
    }
  };

  /* post given data to url with file*/
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData);
  };

  /* post given data to url with file */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData);
  };

  isUserAuthenticated = () => {
    const token = this.getLoggedInUser();
    if (!token) {
      return false;
    }
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        console.warn("Access token expired");
        localStorage.removeItem("token");
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.warn("Error decoding the token:", error);
      return false;
    }
  };

  setLoggedInUser = (token: string) => {
    localStorage.setItem(AUTH_SESSION_KEY, token);
  };

  /* Returns the logged in user*/
  // getLoggedInUser = () => {
  //   return localStorage.getItem(AUTH_SESSION_KEY);
  // };
  // setUserInSession = (modifiedUser: any) => {
  //   const token = localStorage.getItem(AUTH_SESSION_KEY);
  //   if (token) {
  //     this.setLoggedInUser(token);
  //   }
  // };

  getLoggedInUser = () => {
    const token = localStorage.getItem(AUTH_SESSION_KEY);
    if (token) {
      const decoded = jwtDecode<JwtPayload>(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        this.handleSessionExpiration();
        return null;
      }
    }
    return token;
  };
}
const api = new APICore();

// Check if token is available in local storage
const token = localStorage.getItem(AUTH_SESSION_KEY);
if (token) {
  api.setAuthorization(token);
}

export { api, APICore as default };
