import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import manageUser from "./manageUser/reducers";

export default combineReducers({
  Auth,
  Layout,
  manageUser,
});
