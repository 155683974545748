import { ADD_LOGIN_DETAILS } from './constants';
import { UserActionTypes } from './types';

interface UserState {
  loginDetails: {
    email: string;
    password: string;
  };
}

const initialState: UserState = {
    loginDetails: {
      email: '',
      password: '',
    },
};

const manageUserReducer = (state = initialState, action: UserActionTypes):UserState => {
  switch (action.type) {
    case ADD_LOGIN_DETAILS:
      return {
        ...state,
        loginDetails: action.payload,
      };
    default:
      return state;
  }
};

export default manageUserReducer;
