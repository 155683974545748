export const endpoints = {
  registerUser: "/user",
  loginUser: "/user/login",
  verifyLoginOtp: "/user/otp",
  verifyEmail: "/user/verify-email",
  resendVerification: "/user/resend-verification",
  forgetPassword: "/user/reset-token",
  resetPassword: "/user/reset-password",
  resetPasswordNewMerchant: "/user/reset-password/new",
  getUserDetails: "/user/details",
  generateAPIKeys: "/settings/api-keys",
  updateURLs: "/settings/update-urls",
  getTransactions: "/transactions",
  queryTransaction: "/transactions/query",
  getBankCodes: "/payout/bank-codes",
  validatePayoutAccount: (accountNumber: string, bankCode: string) => `/payout/validate-account/${accountNumber}/${bankCode}`,
  addPayoutAccount: "/payout/add-account",
  getPayoutAccount: "/payout/get-payout-account",
  getPayoutAccounts: "/payout/get-payout-accounts",
  updatePayoutAccount: (id: number) => `/payout/update-account/${id}`,
  deletePayoutAccount: (id: number, otp: number) => `payout/delete-account/${id}/${otp}`,
  setDefaultPayoutAccount: (id: number) => `/payout/set-default-account/${id}`,
  getPayoutTransactions: "/payout/transactions",
  getPayoutBalance: "/payout/balance",
  withdrawPayout: "/payout/withdraw",
  sendPayoutOTP: "/payout/sendOtp",
  getDashboardData: "/transactions/dashboard-data",
  getPOSTransactions: "/transactions/pos",
  getPOSSettlements: "/user/get-pos-settlements",
  postImage: "/user/image",
  getHelpPost: "/user/get-help",
  transferPayout: "payout/transfer"
};