import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSession } from '../../contexts/SessionContext';
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const SessionExpiryModal = () => {
    const { isSessionExpired, resetSession } = useSession();
    const navigate = useNavigate();

    const handleClose = () => {
        resetSession();
        navigate('/auth/login');
    };

    return (
        <Modal show={isSessionExpired} onHide={handleClose}>
            <Modal.Body>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <FeatherIcon icon={"alert-triangle"} color="red" />
                    <h3>Session Expiry</h3>
                </div>
                <h5>Your session has expired. Please log in again to continue using the application.</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SessionExpiryModal;