import { useEffect } from "react";
import AllRoutes from "./routes/Routes";
import { SessionProvider, useSession } from "./contexts/SessionContext";
import SessionExpiryModal from "./helpers/api/SessionExpiryModal";
import "./assets/scss/Theme.scss";

const App = () => {
  return (
    <SessionProvider>
      <AppComponent />
    </SessionProvider>
  );
};

const AppComponent = () => {
  const { handleSessionExpiration } = useSession();

  useEffect(() => {
    const handleEvent = () => handleSessionExpiration();
    document.addEventListener('session-expired', handleEvent);

    return () => {
        document.removeEventListener('session-expired', handleEvent);
    };
  }, [handleSessionExpiration]);

  return (
    <>
      <SessionExpiryModal />
      <AllRoutes />
    </>
  );
};

export default App;