import React, { createContext, useContext, useState, useCallback } from 'react';

interface SessionContextType {
    isSessionExpired: boolean;
    handleSessionExpiration: () => void;
    resetSession: () => void;
}

const SessionContext = createContext<SessionContextType | null>(null);

export const useSession = (): SessionContextType => {
    const context = useContext(SessionContext);
    if (context === null) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
    const [isSessionExpired, setSessionExpired] = useState(false);

    const handleSessionExpiration = useCallback(() => {
        setSessionExpired(true);
    }, []);

    const resetSession = useCallback(() => {
        setSessionExpired(false);
    }, []);

    return (
        <SessionContext.Provider value={{ isSessionExpired, handleSessionExpiration, resetSession }}>
            {children}
        </SessionContext.Provider>
    );
};
