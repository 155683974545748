import React from "react";
import {
  Route,
  Navigate,
  RouteProps,
} from "react-router-dom";
import { api } from "../helpers/api/apiCore";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const isUserAuthenticated = api.isUserAuthenticated();

  return (
    <Route
      {...rest}
      element={
        isUserAuthenticated ? (
          < Component />
        ) : (
          <Navigate to="/auth/login" replace />
        )
      }
    />
  );
}

export default PrivateRoute;
